<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-card-text class="text-center">
                    <!-- <v-avatar size="60" class="mb-4">
                        <img src="@/assets/images/logo.svg" alt="" />
                    </v-avatar> -->
                    <img src="@/assets/images/massage-logo-w.png" alt="" />
                    <h5>{{ header_message }}</h5>
                    <h6 class="text--disabled font-weight-medium">
                        {{ subheader_message }}
                    </h6>
                    <v-progress-circular
                        v-if="loading"
                        :size="40"
                        :width="7"
                        color="purple"
                        indeterminate
                    ></v-progress-circular>     

                    <v-container v-if="success">
                        <v-form  v-if="!submitted" v-model="formValid">                                              
                            <v-text-field
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show ? 'text' : 'password'"
                                name="input-10-2"
                                label="Password" 
                                :rules="passwordRules"
                                ref="pwdField"
                                v-model="password"
                                @click:append="show = !show"        
                                :validate-on-blur="true"                    
                            ></v-text-field>
                            <v-text-field
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show ? 'text' : 'password'"
                                name="input-10-2"
                                v-model="retypePassword"
                                label="Confirm Password"
                                :rules="retypePasswordRules"                            
                                @click:append="show = !show"   
                                :validate-on-blur="true"                         
                            ></v-text-field>                           
                            <v-btn class="mb-4" 
                                block 
                                color="primary" 
                                @click="changePassword" 
                                dark
                                :loading="submitting"
                                
                            >{{ $t("Passreset.change password") }}</v-btn>
                            
                        </v-form> 
                        <div class="self-align-center">
                            <v-btn text small color="primary" class="self-align-center" to="/auth/login"
                                >{{ $t('Passreset.proceed to login') }}</v-btn
                            >
                        </div>                        
                    </v-container>                                                          
                </v-card-text>
            </base-card>
        </div>
        <v-snackbar
            v-model="snackbar"
            :timeout="2000"
            top
            tile
            color="red accent-2"
            >
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>
<script>
import api from "src/api/index";
import { fieldNotEmpty, fieldMinLength } from "src/core/vuetify-validators.js"
// import i18n from 'src/lang/lang';
// alert(JSON.stringify(i18n.global));
// const { t } = i18n.global;

export default {
    name: 'ActivateAccount',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Reset Password' //this.$t('password reset')
    },
    data() {
        return {
            show: false,
            header_message: 'Loading...',
            subheader_message: 'Please wait',
            snackbar: false,
            snackbarMessage: '',
            loading: true,         
            success: false,            

            submitted: false,
            submitting: false,
            formValid: true,
            code: '',
            password: '',
            retypePassword: '',


            passwordRules: [
                fieldNotEmpty,             
                (v) => fieldMinLength(v, 8)                
            ],
            retypePasswordRules: [
                this.matchingPasswords
            ],
        }
    },
    methods: {
        validateResetCode() {    
            var self = this;            
            api.auth.validateResetPassCode(self.code)
            .then((rsp)=>{                
                self.header_message = this.$root.$i18n.t("Passreset.password reset");
                self.subheader_message = this.$root.$i18n.t('Passreset.enter your new password');
                self.success = true;
            })
            .catch((error)=>{
                self.header_message = this.$root.$i18n.t('Passreset.hmm thats weird');
                self.subheader_message = error.response.data.message || this.$root.$i18n.t('Passreset.error with reset link');                
            })
            .finally(()=>{
                self.loading = false;
            })
        },
        changePassword() {    
            var self = this;       
            if(self.formValid) {
                self.submitting = true;
                api.auth.changePassword({
                    password: self.password,
                    retypePassword: self.retypePassword,
                    code: self.code
                })
                .then((rsp)=>{                
                    self.header_message = this.$root.$i18n.t('Passreset.password reset completed');
                    self.subheader_message = this.$root.$i18n.t('Passreset.you can now login with new password');
                    self.submitted = true;
                })
                .catch((error)=>{                    
                    this.snackbarMessage = error.response.data.message || "There was an error during password reset: Please contact the administrator";
                    this.snackbar = true;            
                })
                .finally(()=>{                    
                    self.submitting = false;
                })
            }     
            else {

            }            
        },
        matchingPasswords: function() {            
            if (this.password === this.retypePassword) {
                return true;
            } else {
                return this.$root.$i18n.t('Passreset.passwords do not match');
            }
        },
    },
    created() {
        this.code = this.$route.params.code;
        this.$root.$i18n.locale = this.$route.query.lang || 'en'; 
    },
    mounted() {
        this.validateResetCode();
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #f2f4f4 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
